#root {
  width: 100%;
  min-height: 100%;
  overflow: auto;
}
  
html,
body,
#fly-through,
[id^='aad-scene'] {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  outline: none;
  overflow: auto;
}

[id^='aad-scene'] {
  height: calc(100vh - 90px);
}
  
@media screen and (min-width: 768px) {
  html,
  body,
  #root,
  [id^='aad-scene'] {
  overflow: hidden;
  }
  
  [id^='aad-scene'] {
  height: calc(100vh - 60px);
  }

  #fly-through {
    height: 100vh;
  }
}
 